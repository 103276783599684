@import "./colors.scss";
@import "./custom/variable.scss";
@import "./fonts.scss";
@import "./buttons.scss";

.section {
  padding: 45px 10%;
  position: relative;
  &.gray {
    background-color: $sectiongray;
  }
  &.fiveh {
    height: 500px;
  }
  &.tenh {
    height: 1000px;
  }
  &.sevenh {
    height: 750px;
  }
}
.side-by-side {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  &.pad-top-50 {
    padding-top: 50px;
  }
  &.right-align {
    justify-content: flex-end;
  }
}

.section-subhead {
  width: 100%;
  text-align: left;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
}

.mobile-center {
  @media screen and (max-width: 500px) {
    justify-content: center !important;
    text-align: center !important;
  }
}

.mobile-column {
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.hide-mobile {
  @media screen and (max-width: 500px) {
    display: none !important;
  }
}
.hide-tablet {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.side-by-side-empty {
  width: 50%;
  &.seventy {
    width: 60%;
  }
  &.thirty {
    width: 30%;
  }
}

.icon-and-description {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 15px 35px;
  height: 182px;
  text-align: center;
  img {
    height: 90px;
    width: 90px;
    &.golf-ball {
      width: 80px;
    }
  }
  h4 {
    margin-top: 10px;
  }
}

.header-with-subtext {
  width: 450px;
}

.menu-link {
  width: 140px;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  a.link {
    color: white;
    &.blue {
      color: $primary;
      font-weight: 600;
    }
    &:hover {
      cursor: pointer;
      color: $secondary;
    }
  }
  &.cta {
    color: white;
    background-color: $secondary;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid white;
    &:hover {
      background-color: darken($secondary, 3);
      cursor: pointer;
    }
    a.link {
      &:hover {
        color: white;
      }
    }
  }
}

.side-menu-link {
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 20px;
  a {
    font-size: 24px;
  }
}

.small-headline {
  font-size: 16px;
  color: $white;
}

h1 {
  font-size: 64px;
  &.white {
    color: $white;
  }
}

.no-margin {
  margin: 0;
}

.wide-button {
  width: 200px;
}

.tall-button {
  height: 60px;
}

.short-button {
  height: 35px;
  padding: 0px;
}

.filter-buttons {
  height: 30px;
  width: 100%;
  background-color: $gray;
  border-color: $gray;
  text-align: center;

  &.selected {
    background-color: $primary;
    border-color: $primary;
  }
}

.info-box {
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.icon-and-text {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.styles_modal__gNwvD {
  // border-top: 20px solid $primary;
  // border-bottom: 20px solid $primary;
  border-radius: 5px;
  width: 100%;
  max-width: 500px !important;
  input {
    width: 300px;
    margin:0 auto;
    border: 1px solid black;
    border-radius: 4px;
    height: 30px;
  }
  @media screen and (max-width: 768px) {
    border: none;
    height: 100%;
  }
}

.reg-input {
    width: 300px !important;
    margin:0 auto;
    border: 1px solid black;
    border-radius: 4px;
    height: 30px;
}
.reg-light-input {
    width: 300px !important;
    margin:0 auto;
    border-radius: 4px;
    height: 30px;
}
.publish-btn {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex !important;
  justify-content: center;
}
.kep-login-facebook {
  max-width: 300px;
  width: 100%;
  border-radius: 4px;
}
.styles_closeButton__20ID4 {
  &:hover {
    cursor: pointer;
  }
}

.modalBody {
  width: 400px;
}

.modalHeader {
  margin-top: 25px;
  margin-bottom: 25px;
}

.dropdown {
  .dropdown-menu {
    box-shadow: 2px 2px 10px grey;
  }
  .nav-link {
    padding: 0;
  }
}

.toast-container {
  margin-top: 35px;
  .toast-content {
    border-radius: 5px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .Toastify__toast-body {
    padding: 15px;
  }
  .Toastify__toast--success {
    background: white;
    // border: 4px solid green;
    color: $primary;
    font-weight: 500;
  }
  .Toastify__close-button {
    color: $secondary;
  }
}

.StripeCheckout {
  width: 100% !important;
  max-width: 200px !important;
  background: $secondary !important;
  transition: all 0.3s ease !important;
  span {
    background: $primary !important;
    line-height: 34px !important;
    font-size: 18px !important;
    font-family: "Encode Sans Expanded", sans-serif !important;
    font-weight: 600 !important;
    height: 33px !important;
    &:hover {
      background: $primarydark !important;
    }
  }
}

.propertiesContainer {
  // border-bottom: 1px solid $lightgray;
  padding-bottom: 10px;
  // &:hover {
  //   cursor: pointer;
  // }
  .hr-text {
    &:hover {
      cursor: pointer;
    }
  }
}

.edit-background-color {
  // margin-bottom: 15px;
}

.edit-item {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border: 1px solid $sectiongray;
  border-radius: 5px;
  padding: 8px;
  background: $sectiongray;
  margin-bottom: 15px;
  p {
    margin-bottom: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.icon-actions {
  margin-bottom: 25px;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column;
  /* flex-direction: column; */
  -ms-flex-pack: distribute;
  justify-content: space-between;
  height: 100px;
  -ms-flex-align: center;
  align-items: center;
  width: 240px;
  .action-option {
    width: 110px;
    justify-content: center;
  }
}

.section-header {
}

.hr-text {
  line-height: 2em;
  position: relative;
  outline: 0;
  border: 0;
  color: $primary;
  text-align: center;
  height: 2em;
  font-size: 1.1em;
  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, $secondary, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $primary;
    padding: 0 0.3em;
    line-height: 2em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: $primary;
    background-color: $white;
  }
}

#font-picker {
  width: 100% !important;

  .dropdown-button {
    background: $white;
  }
  ul {
    background: $white;
  }
}

.ReactCollapse--content {
  padding-bottom: 20px;
}

.unsplashContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  border-radius: 5px;
  justify-content: space-around;

  .unsplashPhoto {
    height: auto;
    width: 150px;
    object-fit: none;
    padding: 5px;
    border-radius: 25px;
    max-height: 150px;
  }
}

ul {
  padding-inline-start: 0px;
}

.sortableList {
  z-index: 10000000;
}

.styles_overlay__CLSq- {
  z-index: 999999999;
}

.avatarRound {
  border-radius: 50px;
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-top: 30px;
  @media screen and (max-width: 350px) {
    width: 70px;
    height: 70px;
  }
  &.shining {
    margin-top: 0 !important;
  }
}

#avatarInput {
  position: absolute;
  padding: 180px 0 0 0;
  // margin-top: 20px;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.loading-overlay {
  .css-df17o1 {
    z-index: 9999999999 !important;
  }
}

.btn-primary {
  max-width: 300px;
  background-color: $primary;
}

.btn-danger {
  max-width: 300px;
  background-color: red;
}

.slide-pane__overlay {
  top: initial !important;
}

.slide-pane__header {
  display: none !important;
}
.slide-pane__content {
  border-top: 3px solid $primary;
  background: #dcdcdc;
}
.slide-pane_from_bottom {
  top: initial;
  height: 65vh !important;
  min-height: 300px;
  max-height: 1050px;
  margin-top: 0px !important;
}

.editPanelAction {
  position: absolute;
  right: 5%;
  top: 30px;
  .editBtn {
    background: $primary;
    &:hover {
      background: darken($primary, 10%);
    }
    @media screen and (max-width: 769px) {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: 500px) {
    top: 0px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 25px;
  }
}

.unsplash-attribution {
  padding: 6px;
  background: #ffffff2e;
  position: absolute;
  bottom: 60px;
  right: 10px;
  border-radius: 5px;
  color: #a2a2a2;
  a {
    color: #a2a2a2;
    &:hover {
      color: #fff;
    }
  }
}

.idea-header {
  height: 150px;
  text-align: center;
  .body {
    height: 250px;
    text-align: center;
  }
}

#full-content {
  // margin-top: 69px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.half-width-content {
  width: 50%;
  // text-align: center;
  position: relative;
  .body-content {
    padding: 30px;
    background-color: #ececec;
    height: 100%;
    min-height: 37vh;
  }

  .preview-content {
    text-align: center;
    height: 100%;
    min-height: 97vh;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.editable-button {
  background: transparent;
  height: 30px;
  width: 30px;
  border: none;
  margin: 0 auto;
  &.pad {
    margin-right: 10px;
    &:last-of-type {
      margin-left: 10px;
    }
  }
}

.styles_Editext__main_container__2Y-IL {
  align-items: center;
}

.styles_Editext__view_container__2l2kB {
  max-width: 85%;
  line-height: 40px;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
}

.styles_Editext__input__1534X {
  padding: initial !important;
}

.avatar-image {
  position: relative;
  border-radius: 50px;
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin: 0 auto;
  // margin-top: 30px;
  margin-bottom: 20px;
}
.avatar-image img {
  width: 100%;
  vertical-align: top;
}
.avatar-image:after,
.avatar-image:before {
  position: absolute;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.avatar-image:after {
  content: "\A";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background:rgba(0,0,0,0.6);
  border-radius: 150px 150px 150px 150px;
}
.avatar-image:before {
  content: attr(data-content);
  width: 100%;
  color: #fff;
  z-index: 1;
  bottom: 17px;
  padding: 40px 10px;
  text-align: center;
  background: #2125297a;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 150px 150px 150px 150px;
}
.avatar-image:hover:after,
.avatar-image:hover:before {
  opacity: 1;
  cursor: pointer;
}

.avatarRound {
  margin-top: 50px !important;
}

.linkable-edit-action-button {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  background: transparent;
  margin: 0 auto;
  text-align: center;
  margin: 4px;
  // box-shadow: 2px 2px 10px grey;
  &:hover {
    cursor: pointer;
  }
}

.addLink {
  padding: 10px;
  border: 3px dotted $primary;
  width: 85%;
  color: $primary;
  height: 50px;
  margin: 0 auto;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000ad;
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
    color: white;
    background: #000000a2;
  }
}

.linkView {
  width: 100%;
  cursor: move;
}

.linkable {
  transition: all 0.3s ease-in-out;
  min-height: 50px;
  max-height: 65px;
  height: 100%;
  // align-items: center;
  border: 2px;
  // display: flex;
  // width: 80%;
  &:hover {
    transform: scale(1.01);
  }
  div {
    // font-size: 24px;
  }
}

// animations
.shaking {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.wiggle {
  animation: wiggle 4.5s infinite;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  85% { transform: rotate(2.5deg); }
  95% { transform: rotate(-2.5deg); }
 100% { transform: rotate(0deg); }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.makeStyles-textField-2 {
  width: 100%;
  max-width: 240px;
}

.flex-container {
  display: flex; /* establish flex container */
  flex-wrap: wrap; /* enable flex items to wrap */
  justify-content: center;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
}

.grid-linkable {
  &.in-edit {
    // height: 15vw;
    // width: 15vw;
  }
  // transition: all 0.3s ease-in;
  height: 32vw;
  width: 32vw;
  max-width: 190px;
  max-height: 190px;
  align-items: center;
  display: flex;
  // flex: 0 0 28%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff42;
  margin: 2px;
  // margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // maintain aspect ratio
  // width: 30vw;
  // height: 27vw;
  // width: 200px;
  position: relative;
  div {
    font-size: 24px;
    // transition: all 0.2s ease-in-out;
    p {
      display: none;
      font-size: 24px;
      text-align: center;
    }
  }

  &:hover {
    transform: scale(1);
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffffb6;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        display: block;
      }
    }
  }
}

.linkable-settings {
  position: absolute;
  right: 5px;
}

.react-tabs__tab-list {
  margin: 0 !important;
}
.react-tabs__tab {
  width: 50%;
  text-align: center;
}

.block-picker {
  z-index: 2;
  position: absolute !important;
  top: 30px;
}
div.block-picker > div:nth-child(1) {
  left: 10% !important;
}

.my-account-table {
  width: 100%;
  font-size: 25px;
}
.table-label {
  color: $primary;
}

.fixed-back-button {
  position: fixed;
  top: 45px;
  left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fixed-publish-button {
  position: fixed;
  top: 45px;
  right: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-mockup {
  max-width: 650px;
  width: 100%;
  max-height: 600px;
  height: 100%;
  margin-top:25vh;
}

.white-overlay {
  background: rgba(255, 255, 255, 0.82);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.row-of-images {
  position: relative;
  height: 350px;
  max-width: 1200px;
  margin: 0 auto;
  img {
    position: absolute;
    width: 350px;
    @media screen and (min-width: 960px) {
      width: 450px;
    }
  }
}
#far-left {
  left: 3%;
  top: 130px;
  z-index: 0;
  @media screen and (min-width: 850px) {
    left: 6%;
  }
  @media screen and (min-width: 960px) {
    left: 6%;
  }
}
#left {
  left: 13%;
  top: 75px;
  z-index: 1;
  @media screen and (min-width: 850px) {
    left: 16%;
  }
  @media screen and (min-width: 960px) {
    left: 16%;
  }
}
#center {
  left: 0;
  right: 0;
  margin: auto;
  top: 40px;
  z-index: 2;
}
#right {
  right: 13%;
  top: 75px;
  z-index: 1;
  @media screen and (min-width: 850px) {
    right: 16%;
  }
  @media screen and (min-width: 960px) {
    right: 16%;
  }
}
#far-right {
  right: 3%;
  top: 140px;
  z-index: 0;
  @media screen and (min-width: 850px) {
    right: 6%;
  }
  @media screen and (min-width: 960px) {
    right: 6%;
  }
}
.feature-image-container {
  margin: 0 auto;
  text-align: center;
  img {
    max-height: 350px;
    padding: 25px;
  }
}
.faq-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.menu-item {
  border-radius: 50px;
  margin-right: 10px;
  background: $white;
  color: $primary;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1) !important;
    background: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
  }
  &.active {
    color: $white;
    background: transparent;
    border: 1px solid $white;
  }
}

.menu-item-container {
  display: flex;
  justify-content: space-around;

  width: 100%;
  padding: 15px;
      .nav-item {
      padding: 8px;}
  @media screen and (max-width: 769px) {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    background: $bg_gradiant;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    .nav-item {
      padding: 10px;
      text-align: center;
      max-width: 220px;
      margin: 0 auto;
    }
  }
  .web-only {
    @media screen and (min-width: 769px) {
      // width: 220px;
      display: flex;
      justify-content: space-around;
      li > a {
        width: 120px;
      }
      .web-only {
        .complex-link {
          margin-top: 0px;
          margin-right: 20px;
        }
      }
    }
  }
}
.complex-link {
  margin-top: 8px;
}

.headroom--scrolled {
  @media screen and (max-width: 768px) {
    transform: translate3d(0px, 0%, 0px) !important;
  }
}
.headroom-disable-animation {
  @media screen and (max-width: 768px) {
    transform: translate3d(0px, 0%, 0px) !important;
  }
}

.navbar-collapse {
  @media screen and (max-width: 769px) {
    p {
      color: white;
    }
    text-align: center;
    a {
      color: white;
    }
  }
}

.web-only {
  display: block;
  @media screen and (max-width: 769px) {
    display: none;
  }
}
.mobile-only {
  display: none;
  @media screen and (max-width: 769px) {
    display: block;
  }
}

.label-input-pair-center {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  label {
    font-size: 24px;
  }
  input {
    width: 100%;
    text-align: center;
  }
}
.label-input-pair-left {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  label {
    font-size: 24px;
  }
  input {
    width: 100%;
    text-align: left;
  }
}
.margin-bottom {
  margin-bottom: 20px !important;
}
.horizontal-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .header-image-and-name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 10%;
    // width: 100%;
  }
  .header-title-and-subtitle {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
}
.centered-heading {
  justify-content: center;
  display: flex;
  flex-direction: column;
  .header-image-and-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.report-btn {
  position: absolute;
  bottom: 20px;
  right: 10px;
  &:hover {
    cursor: pointer;
  }
}
.linkstackr-logo {
  position: absolute;
  bottom: 20px;
  left: 10px;
  &:hover {
    cursor: pointer;
  }
}
.hidden {
  display: none;
}

.large-input {
  border: 2px solid $primary;
    line-height: 2;
    height: 40px;
    font-size: 24px;
}

.listcontainerparent {
  margin-top: 24px;
  .List {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.listcontainervertical {
  width: 80%;
  max-width: 600px;
  margin-top: 24px;
  .List {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .draggable {
      width: 100%;
      max-width:600px;
    }
  }
}


.add-links-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media screen and(max-width: 850px){
    flex-direction: column;
  }
}

/**
 * The "shine" element
 */
.shine-icon {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin-top: 25px;
  border-radius: 50px;
  @media screen and (max-width: 350px) {
    width: 70px;
    height: 70px;
  }
}
 .shine-icon:after {

  animation: shine 5s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

/* Hover state - trigger effect */


/* Active state */

.shine-icon:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

.pulse-container {
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 64px;
  z-index: -1;
  height: 75px;
  width: 75px;
  margin: 0 auto;
}
.pulse-container:before,
.pulse-container:after, .pulse-container > span {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
  animation: glow-grow 6s ease-out infinite;
}
.pulse-container:after {
  animation-delay: 3.66s;
}
.pulse-container {
  span{
    animation-delay: 1.33s;
    }
}
@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.edit-side-by-side {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  input {
    width: initial;
    margin-bottom: 9px;
    margin-left: initial;
    margin-right: 15px;
  }
}

.makeStyles-textField-2 {
  width: 300px;
  max-width: 350px;
}

.MuiFormControl {
  display: initial;
}
.MuiFormControl-root {
  display: initial !important;
}