$primary: #4961b1;
$primarydark:#3f5291;
$secondary: #2DBECF;
$white: #fff;
$lightgray: #a5a5a5;
$sectiongray: #f9f9f9;
$gray: rgb(184, 184, 184);
$darkgray: #a8a8a8;
$darkergray: #686868;
$transparent_overlay_white: rgba(255, 255, 255, 0.35);
$transparent_overlay_black: rgba(0, 0, 0, 0.6);
$transparent_overlay_gray: rgba(0,0,0, 0.2);
$transparent_overlay_primary: rgba(73, 131, 219, 0.8);
$black: #000000;
$orange: #ef9916;
$gold: #ffca59;
$red: #f02222;