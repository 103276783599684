a.button {
  display:flex;
  padding: 10px 20px;
  background-color: gray;
  color: white !important;
  border-radius: 5px;
  height: 40px;
  width: 105px;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: darken(gray, 10)
  }
}

.short {
  display:flex;
  padding: 0px;
  color: white !important;
  border-radius: 5px;
  height: 35px;
  width: 20%;
  align-items: center;
  justify-content: center;
}

.wide {
  width: 100%;
  max-width: 200px;
}

.margin-right {margin-right: 5%;}