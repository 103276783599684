@import "./colors.scss";
@import url('https://fonts.googleapis.com/css?family=Lato|Cabin|Merriweather|Montserrat|Noto+Sans|Open+Sans|Oswald|PT+Sans|Poppins|Raleway|Roboto|Roboto+Condensed|Roboto+Slab|Slabo+27px|Source+Sans+Pro|Ubuntu');
body,
p {
  font-family: "Lato", sans-serif;
  margin-bottom: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 500px;
  margin: 0 auto;

  &.bold {
    font-weight: 700;
  }
  &.expanded {
    font-family: "Lato", sans-serif;
  }
  &.expanded-bold {
    font-family: "Lato", sans-serif;
    font-weight: 600;
  }
  &.light {
    font-family: "Lato", sans-serif;
    font-weight: 300;
  }
  &.heading {
    color: black;
    font-size: 46px;
    padding-bottom: 35px;
  }
    &.primary {
    color: $primary;
  }
  &.white {
    color: $white;
  }
  &.black {
    color: $black;
  }
}
.center-text {
  text-align: center;
}
a {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-decoration: none;
  font-size: 16px;
  color: white;
  transition: all 0.25s;
  &:hover {
    color: $orange;
    text-decoration: none;
  }
  &.orange {
    color: $orange;
  }
  &.primary {
    color: $primary;
  }
  &.black {
    color: $black;
    &:hover {
      cursor: pointer;
      color: $primary !important;
    }
  }
  &.title {
    font-size: 24px;
  }
}
.white {
  color: $white;
}

.gray {
  color: $darkgray;
}

p {
  margin-bottom: 0;
}

a.underline-orange {
  text-decoration: underline;
  color: $orange;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $primary;
  }
}
