/*-----------------------------------------------------------------------------------

    Template Name:Chatloop APP
    Template URI: themes.pixelstrap.com/chatloop
    Description: This is App Landing Page
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

01.General CSS
02.Pre-loader & tap on top css
03.Home page css
04.about section css
05.feature section css
06.testimonial section css
07.screenshots section css
08.work section css
09.Team section css
10.price section css
11.Blog section css
12.Download section css
13.Contact section css
14.footer section css
15.Blog pages css
16.inner Page css
17 Review page css
18.Faq Page css
19.Download page css
20.coming soon pages css
21.authentication pages css
22.Landing page css(is's only for theme showcase you can remove it :))
23.responsive css
 */

@import "./variable";
@import "./rtl";
@import "./responsive";
/*=====================
    01.General CSS start
==========================*/
body {
  font-family: $font_0, $font_1;
  background-color: $white;
  position: relative;
  font-size: 14px;
  overflow-x: hidden;
}
h1 {
  line-height: 1.4em;
  font-size: 42px;
  color: hsla(0, 0%, 100%, 0.85);
}
h2 {
  line-height: 28px;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: $primary_color;
}
h3 {
  line-height: 28px;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  color: #586082;
  letter-spacing: 1px;
}
h4 {
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 0;
  color: $white;
  letter-spacing: 1px;
}
h5 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 0;
  letter-spacing: 0.1em;
  color: $white;
}
h6 {
  line-height: 28px;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 25px;
  color: $theme-gray;
  margin-bottom: 25px;
}
p {
  line-height: 23px;
  font-size: 16px;
  color: $theme-gray;
  letter-spacing: 0.05rem;
}
a {
  &:hover {
    color: $secondary_color;
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.theme-bg {
  background: $bg_gradiant;
}

.page-margin {
  margin-top: -40px;
}
.owl-theme {
  &.owl-carousel {
    .owl-nav {
      button {
        &.owl-next,
        &.owl-prev {
          background-color: $white !important;
          padding: 5px 15px !important;
          font-size: 18px;
          color: $primary_color;
        }
      }
    }
  }
  .owl-dots {
    height: 17px;
    .owl-dot {
      span {
        background-color: $white;
        border: 1px solid $primary_color;
      }
      &.active,
      &:hover {
        span {
          background: $bg_gradiant;
          border: 1px solid $white;
        }
      }
    }
  }
  .owl-dots {
    .owl-dot {
      span {
        margin-bottom: 0;
      }
    }
  }
  &.owl-nav {
    .owl-dots {
      .owl-dot {
        span {
          margin-bottom: 0;
        }
      }
    }
    [class*="owl-"]:hover {
      background: transparent;
    }
  }
}
.text-black {
  color: $black !important;
}
button:focus {
  outline: none;
}
.f-bold {
  font-weight: 700;
}
.f-color {
  color: $theme-gray;
}
.ml-12 {
  margin-left: 12px;
}
.section-title {
  margin-bottom: 50px;
  &.center {
    margin: 0 auto;
  }
}
.theme-text {
  color: $secondary_color !important;
}
.margin-50 {
  margin-top: 50px;
}
.btn-theme {
  background-color: $primary_color;
  background: $bg_gradiant;
  color: $white !important;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 30px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  border: 0;
  letter-spacing: 1px;
  &:hover {
    background: $primary_color;
    background-color: $primary_color;
  }
}
.btn-theme-primary {
  background-color: $primary_color;
  color: $white !important;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease-in;
  border: 1px solid $primary_color;
  &:hover {
    background-color: transparent;
    color: $primary_color !important;
    border: 1px solid $primary_color;
    transition: all 0.3s ease-in-out;
  }
}
.d-flex-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.display-flex {
  display: flex;
  align-items: center;
}
#instantclick-bar {
  background: $secondary_color;
}
section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}
.margin-top-100 {
  margin-top: 110px;
}
/*=====================
    01.General CSS end
==========================*/
/*=====================
    02.Pre Loader start & tap on top
==========================*/
.tap-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 150px;
  right: 30px;
  z-index: 99;
  color: $white;
  text-align: center;
  background: $bg_gradiant;
  border-radius: 100%;
  font-size: 22px;
  border: 1px solid $white;
  cursor: pointer;
  display: none;
  i {
    vertical-align: middle;
  }
}
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: $white;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1031;
  top: 0;
}
#loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
#box {
  width: 50px;
  height: 50px;
  background: $bg_gradiant;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
#shadow {
  width: 50px;
  height: 5px;
  background: $black;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}
/*=====================
    02.Pre Loader start & tap on Ends
==========================*/

/*=====================
    03.Home page css start
==========================*/

.headroom-wrapper {
  // height: 50px !important;
  background: $primary_color;
  .headroom--pinned {
    background: $primary_color;
  }
  .headroom--unpinned {
    background: $primary_color;
  }
}
.theme-nav {
  padding-top: 0px;
  padding-bottom: 0px;
  transition: all 0.3s ease;
  .navbar {
    background: $bg_gradiant;
  }
  .nav-link {
    display: block;
    padding: 25px 1rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
  }
  .nav-item {
    font-size: 14px;
    padding: 20px 5px;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
  }
  .dropdown-menu {
    .nav-item {
      font-size: 13px;
      padding: 0;
    }
    .nav-link {
      color: $primary_color !important;
    }
  }
  &.darkHeader {
    padding-top: 0;
    padding-bottom: 0;
    height: 50px !important;
    .nav-item {
      padding: 0px 5px;
      transition: all 0.3s ease;
    }
    .dropdown-menu .nav-item {
      padding: 0;
      .nav-link {
        &.active {
          color: #18e7d3;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .navbar-nav .active > .nav-link {
    color: $secondary_color;
  }
  .navbar-toggler-icon i {
    color: $white;
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.navbar-toggler {
  padding-right: 0;
  .navbar-toggler-icon {
    width: auto;
  }
}
.navbar-nav {
  .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }
  > li.dropdown {
    position: relative;
  }
  a {
    &.nav-link {
      &.active {
        color: $white;
      }
    }
  }
}
.btn-menu {
  background-color: $secondary_color;
  color: $white !important;
  border-radius: 50px;
  padding: 10px 54px;
  width: 150px;
  text-align: center;
}
.darkHeader {
  background: $bg_gradiant;
  box-shadow: 1px 1px 35px 0 $color_mine_shaft_40_approx;
  transition: all 0.3s ease;
  height: 60px;
}
.slide-bg {
  background: $bg_gradiant;
  height: 100vh;
  padding-bottom: 0;
  // padding-top: 80px;
  .container {
    height: 100%;
    .row {
      height: 100%;
      .home-right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
  &.white {
    background: $white;
  }
  &.lightgray {
    background: lightgray;
  }
}
#home-hero {
  min-height: 100vh;
  overflow: hidden;
  color: #ffffff;
  padding-top: 0 !important;
}
.slide-text {
  margin-top: -20px;
  h4 {
    font-size: 18px;
    color: $black;
    font-weight: 400;
  }
}
.slid-btn {
  margin-top: 40px;
  a:first-child {
    margin-right: 15px;
  }
}
.main-title {
  color: $primary_color;
  font-weight: 600;
  font-size: 40px !important;
}
.sub-title {
  font-size: 20px;
  margin-bottom: 0;
}
.mobile-slid {
  text-align: center;
}
.profile-msg {
  position: absolute;
  top: 41%;
  left: -25px;
  img {
    animation: movebounce 2.1s linear infinite;
  }
}
@keyframes movebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0px);
  }
}
.awesome {
  position: absolute;
  top: 56%;
  right: 25px;
  img {
    animation: moveleftbounce 2.1s linear infinite;
  }
}
.profile-1 {
  position: absolute;
  bottom: 7%;
  right: -30px;
  &:before {
    animation: 2.5s anim-effct-slide 0s linear infinite;
    box-shadow: 0 0 0 80px $color_gray_nurse_approx;
    position: absolute;
    content: "";
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}
.emoji {
  position: absolute;
  left: 10%;
  bottom: 15%;
  animation: moveleftbounce 2.1s linear infinite;
}
.profile-2 {
  position: absolute;
  left: 0;
  top: 5%;
  &:before {
    animation: 2.5s anim-effct-slide1 0s linear infinite;
    box-shadow: 0 0 0 80px rgba(232, 232, 232, 0.1);
    position: absolute;
    content: "";
    width: 110px;
    height: 110px;
    top: 26%;
    left: 26%;
    border-radius: 50%;
    z-index: 0;
  }
}
@-webkit-keyframes anim-effct-slide {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
@keyframes anim-effct-testi {
  0% {
    box-shadow: 0 0 0 8px $color_bon_jour_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
@-webkit-keyframes anim-effct-testi-back {
  0% {
    box-shadow: 0 0 0 8px $color_cararra_11_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_cararra_11_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px $color_cararra_11_approx;
    opacity: 1;
  }
}
@keyframes anim-effct-testi-back {
  0% {
    box-shadow: 0 0 0 8px $color_cararra_11_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_cararra_11_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px $color_cararra_11_approx;
    opacity: 1;
  }
}
@-webkit-keyframes anim-effct-slide1 {
  0% {
    box-shadow: 0 0 0 8px rgba(232, 232, 232, 0.1);
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 15px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 25px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
/*=====================
    03.Home page css ends
==========================*/

/*=====================
    04.about section start
==========================*/
.about-box {
  border-bottom: 1px solid $color_alto_approx;
  padding-bottom: 58px;
}
.about-border {
  border-right: 1px solid $color_alto_approx;
}
.chat-box {
  text-align: center;
  img {
    transition: all 0.5s;
  }
  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.5s;
    }
  }
}
.chat-slide {
  text-align: center;
  padding-top: 58px;
  h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
}
/*=====================
    04.about section ends
==========================*/

/*=====================
    05.feature section start
==========================*/
.feature {
  .animation-circle-inverse {
    i {
      top: 52%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .row {
    align-items: center;
  }
}
.future-box {
  padding: 60px 0;
}
.future-mobile {
  margin-bottom: -33px;
  text-align: center;
}
.screenshots {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 90%;
    max-width: 90%;
    margin-left: 5%;
  }
}
.future-timeline {
  text-align: right;
  &:after {
    content: "";
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    height: 100%;
    width: 1px;
    right: 0;
    background-size: cover;
    border-radius: 12px;
    top: 0;
  }
}
.timeline {
  &:last-child {
    p {
      margin-bottom: 0;
    }
  }
  h4 {
    color: $white !important;
    margin-top: 0;
  }
  p {
    color: $white;
    margin-bottom: 55px;
    line-height: 1.8em;
    margin-left: 12px;
    font-weight: 300;
    margin-right: 15px;
  }
  // &:before {
  //   content: "";
  //   background: $secondary_color;
  //   position: relative;
  //   height: 12px;
  //   width: 12px;
  //   right: -21px;
  //   background-size: cover;
  //   top: 15px;
  //   border-radius: 50%;
  //   z-index: 2;
  //   float: right;
  //   padding: 0;
  //   border: 3px solid rgba(255, 255, 255, 0.9);
  // }
}
.timeline-right {
  h4 {
    color: $white !important;
    margin-top: 0;
  }
  p {
    color: $white;
    margin-bottom: 55px;
    margin-left: 12px;
    line-height: 1.8em;
    font-weight: 300;
  }
  &:last-child {
    p {
      margin-bottom: 0;
    }
  }
  &:before {
    content: "";
    background: $secondary_color;
    position: relative;
    height: 12px;
    width: 12px;
    left: -21px;
    top: 15px;
    border-radius: 50%;
    z-index: 2;
    float: left;
    padding: 0;
    border: 3px solid rgba(255, 255, 255, 0.9);
  }
}
// .future-timeline-right:after {
//   content: "";
//   background-color: rgba(255, 255, 255, 0.3);
//   position: absolute;
//   height: 100%;
//   width: 1px;
//   left: 0;
//   background-size: cover;
//   border-radius: 12px;
//   top: 0px;
// }
/*=====================
    05.feature section ends
==========================*/

/*=====================
    06.testimonial section start
==========================*/
.testimonial {
  // background-image: $url_1;
  background-position: center;
  background-attachment: fixed;
}
.testimonil-box {
  margin-top: -35px;
  margin-bottom: -20px;
  h4 {
    color: $primary_color;
  }
  .owl-dots {
    text-align: left;
    margin-left: 243px;
    position: absolute;
    bottom: 20px;
    z-index: 99;
  }
}
.testimonial-slider .owl-item img {
  display: block;
  width: 105px;
}
.testi-profile {
  margin: 30px 0 25px;
  .sub-title {
    margin-bottom: 15px;
    color: $primary_color;
  }
  .media-body {
    padding: 0 200px 0 250px;
  }
}
.animated-circle {
  position: absolute;
  margin-right: 50px;
  margin-left: 90px;
  top: 50px;
  &:before {
    animation: 2.5s anim-effect-front 0s linear infinite;
    box-shadow: 0 0 0 10px $primary_color;
    position: absolute;
    content: "";
    width: 105px;
    height: 105px;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: 0;
  }
  &.three {
    &:before {
      animation: 2.5s anim-effect-front 0s linear infinite;
      box-shadow: 0 0 0 10px $color_bay_of_many_45_approx;
    }
    &:after {
      animation: 2.5s anim-effect-back 0s linear infinite;
      box-shadow: 0 0 0 10px $color_bay_of_many_45_approx;
    }
  }
}
@-webkit-keyframes anim-effect-front {
  0% {
    box-shadow: 0 0 0 15px $color_bon_jour_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 30px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
@keyframes anim-effect-front {
  0% {
    box-shadow: 0 0 0 10px $color_bay_of_many_45_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 30px $color_bon_jour_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 40px rgba(224, 224, 224, 0);
    opacity: 0;
  }
}
@-webkit-keyframes anim-effect-back {
  0% {
    box-shadow: 0 0 0 30px $color_cararra_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 50px $color_cararra_60_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 80px rgba(236, 236, 236, 0);
    opacity: 1;
  }
}
@keyframes anim-effect-back {
  0% {
    box-shadow: 0 0 0 20px $color_cararra_11_approx;
    opacity: 1;
  }
  50% {
    box-shadow: 0 0 0 50px $color_cararra_11_approx;
    opacity: 1;
  }
  100% {
    box-shadow: 0 0 0 70px $color_cararra_11_approx;
    opacity: 1;
  }
}

/*=====================
    06.testimonial section ends
==========================*/

/*=====================
    07.screenshots section start
==========================*/
.theme-bg {
  .animation-circle {
    i {
      bottom: 0;
    }
  }
}
.slider-mobile {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 0;
}
.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.swiper-gallery-container {
  text-align: center;
  font-size: 18px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-container-3d {
  .swiper-slide-shadow-left {
    background-image: none;
  }
  .swiper-slide-shadow-right {
    background-image: none;
  }
}
.swiper-pagination-custom {
  position: relative;
  margin-top: 55px;
}
.swiper-pagination-fraction {
  position: relative;
  margin-top: 55px;
}
#slider {
  .swiper-pagination-custom {
    position: absolute;
    margin-top: 30px;
    bottom: 30px;
  }
  .swiper-pagination-fraction {
    position: absolute;
    margin-top: 30px;
    bottom: 30px;
  }
  &.swiper-container-horizontal > .swiper-pagination-bullets {
    position: absolute;
    margin-top: 30px;
    bottom: 30px;
  }
}
/*=====================
    07.screenshots section ends
==========================*/

/*=====================
    08.work section start
==========================*/
.process-box {
  box-shadow: 0px 1px 5px 3px #f9f9f9;
  background: $white;
  border: 1px solid rgba(89, 96, 130, 0.1);
  padding: 30px 25px 25px;
  &:hover {
    .bg-icon {
      transform: scale(2.5) rotate(5deg);
      transition: all 0.3s ease;
    }
  }
  .bg-icon {
    opacity: 0.05;
    transform: scale(2.5) rotate(15deg);
    position: absolute;
    left: 80px;
    bottom: 60px;
    transition: all 0.3s ease;
  }
  p {
    margin-bottom: 0;
  }
  img {
    height: 60px;
  }
  h3 {
    margin-top: 30px;
  }
}
/*=====================
    08.work section ends
==========================*/

/*=====================
    09.Team section start
==========================*/
.team-hover {
  border: 1px solid $primary_color;
  border-radius: 5px;
  padding: 15px;
}
.hover-text {
  h3 {
    margin-top: 0;
  }
  h5 {
    margin-top: 5px;
    margin-bottom: 10px;
    color: $secondary_color;
  }
  p {
    margin-bottom: 0;
  }
}
.team-close-btn {
  position: absolute;
  background-color: $secondary_color;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  right: 15px;
  width: 35px;
  height: 35px;
  top: 0;
}
.team-box {
  position: relative;
  padding: 4px;
  border: 1px solid $color_alto_approx;
  border-radius: 5px;
}
.team-under-box {
  border: 2px solid transparent;
  border-radius: 5px;
  transition: all 0.8s;
  img {
    border: 1px solid $color_alto_approx;
    border-radius: 5px;
    transition: all 0.5s;
  }
  &:hover {
    border: 2px solid $primary_color;
    position: relative;
    .team-under-box-button {
      display: flex;
    }
    img {
      border-color: transparent;
      transition: all 0.5s;
    }
    .social-icon {
      display: block;
      transition: all 0.5s ease-in-out;
    }
  }
}
.team-under-box-button {
  position: absolute;
  height: 35px;
  width: 35px;
  background-color: $primary_color;
  display: none;
  justify-content: center;
  align-items: center;
  right: -1px;
  transition: all 0.5s;
  border-radius: 0 0 0 5px;
  cursor: pointer;
}
.social-icon {
  display: none;
  padding: 15px;
  background: $bg_gradiant;
  text-align: center;
  transition: all 0.3s;
  position: absolute;
  border-radius: 3px;
  bottom: 0;
  left: -1px;
  width: calc(100% + 2.5px);
  li {
    display: inline-block;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
    color: $white;
    line-height: 35px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      color: $white;
      transition: all 0.2s ease-in;
    }
  }
}

/*=====================
    09.Team section ends
==========================*/

/*=====================
    10.price section start
==========================*/
.package-box {
  width: 330px;
  background: $bg_gradiant;
  padding: 30px 0 0;
  transform: scale(1);
  transition: all 0.5s;
  border-radius: 5px;
  h3 {
    color: $white;
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.price-box {
  background: $bg_gradiant;
  //   border: 1px solid $white;
  //   border-left: 0;
  //   border-radius: 0;
  h5 {
    line-height: 1em;
    font-weight: 600;
    margin-top: 0;
    vertical-align: middle;
    display: inline-block;
  }
  h2 {
    font-size: 60px;
    color: $white;
    display: inline-block;
    line-height: 60px;
    vertical-align: middle;
    transition: all 0.5s;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 0 5px;
  }
  span {
    display: inline-block;
    color: $white;
    font-size: 16px;
    vertical-align: middle;
    transition: all 0.5s;
  }
}
.theme-bg {
  &:hover {
    .plan-slider,
    .plan-slider-rtl {
      .owl-prev,
      .owl-next {
        transform: translateX(0px);
        opacity: 1;
        transition: all 0.3s ease;
        border-radius: 100%;
      }
    }
  }
}
.plan-slider,
.plan-slider-rtl {
  .owl-nav {
    margin-top: 0;
    .owl-prev,
    .owl-next {
      position: absolute;
      top: 40%;
      opacity: 0;
      transition: all 0.3s ease;
    }
    .owl-prev {
      left: -70px;
      transform: translateX(150px);
    }
    .owl-next {
      right: -70px;
      transform: translateX(-150px);
    }
  }
}
.price-plan {
  background: $bg_gradiant;
  padding: 30px 10px 30px;
  li {
    margin-bottom: 25px;
    font-size: 16px;
    color: $white;
    letter-spacing: 0.05rem;
  }
}
.price-plan-btn {
  padding: 8px 0;
  width: 140px;
  background: linear-gradient(to right, $white 0%, $primary_color 100%);
  font-size: 16px;
  color: $secondary_color;
  transition: all 0.5s;
  cursor: pointer;
  margin: 10px auto 0;
  border-radius: 5px;
  display: inline-block;
}
/*=====================
    10.price section ends
==========================*/

/*=====================
    11.Blog section start
==========================*/
.news-slid {
  margin-bottom: 40px;
  .news-box {
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: $bg_gradiant;
      transition: all 0.3s ease;
      opacity: 0;
    }
  }
  .blog-hover {
    position: absolute;
    z-index: 9;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    transform: translateY(40px);
    transition: all 0.3s ease;
    h4 {
      margin-bottom: 15px;
    }
    .blog-details-list {
      li {
        display: inline-block;
        a {
          color: rgba(255, 255, 255, 0.75);
          font-weight: 200;
        }
        & + li {
          padding-left: 7px;
          margin-left: 7px;
          line-height: 1;
          border-left: 1px solid $white;
        }
      }
    }
  }
  &:hover {
    img {
      transform: rotate(5deg) scale(1.22);
      transition: all 0.3s ease;
    }
    .blog-hover {
      transform: translateY(00px);
      transition: all 0.3s ease;
    }
    .news-box {
      &:before {
        opacity: 0.7;
        transition: all 0.3s ease;
        z-index: 9;
        left: 0;
      }
    }
  }
  img {
    transition: all 0.3s ease;
  }
}
.blog-details {
  .news-text {
    padding: 30px 0 20px;
    .btn-theme {
      margin-bottom: 40px;
      margin-top: 25px;
    }
    h4 {
      margin-top: 20px;
    }
  }
}
.news-text {
  padding-top: 15px;
  h5 {
    color: $color_mountain_mist_approx;
    font-size: 13px;
    margin-top: 0;
    line-height: 1;
  }
}
.news-slid {
  .btn-theme {
    padding: 5px 14px;
    font-size: 13px;
    background: #fff;
    border: 1px solid $primary_color;
    color: $primary_color !important;
  }
}
.news-carousel {
  .news-slid {
    margin-bottom: 0;
  }
}
.blog-block:hover img {
  transition: all 0.3s ease;
}
/*=====================
    11.Blog section ends
==========================*/

/*=====================
    12.Download section start
==========================*/
.download {
  position: relative;
  text-align: center;
  margin: 0 auto;
  .manual-down {
    color: #a9a9a9;
  }
  h3 {
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 28px;
  }
  h4 {
    color: #444;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .icon {
    height: 150px;
  }
}
.download-bg {
  background: $bg_gradiant;
  padding: 40px 0;
}
.footer-logo {
  padding: 20px 0;
}
.top-bottom-padng {
  margin-bottom: 50px;
  margin-top: 55px;
}
.download-text {
  h3 {
    margin-top: 0;
    color: $white;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 0;
  }
}
.download-img {
  ul {
    li {
      margin-right: 7px;
      display: inline-block;
      margin-top: 0px;
    }
  }
  img {
    width: 120px;
  }
}

/*=====================
    12.Download section ends
==========================*/

/*=====================
    13.Contact section start
==========================*/
.contact {
  .auth-form {
    padding-right: 150px;
  }
}
.contact-box {
  position: relative;
  li {
    padding-left: 70px;
    position: relative;
    & + li {
      margin-top: 35px;
    }
  }
  h3 {
    font-size: 18px;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}
.contact-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid $primary_color;
  background: transparent;
  font-size: 20px;
  color: $primary_color;
  position: absolute;
  left: 0;
  text-align: center;
  line-height: 2.1;
  top: 4px;
  i {
    vertical-align: middle;
  }
}
.contact-text {
  h5 {
    margin-top: 0;
    font-weight: 300;
  }
}
.subscribe-bg {
  background: $bg_gradiant;
  padding: 40px 0;
  &.p-small {
    padding: 25px 0;
  }
}
.subscribe {
  border-right: 1px solid $white;
  padding: 30px 0;
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    color: $white;
  }
}
.email-box {
  padding: 20px 40px;
  .form-control {
    display: inline-block;
    width: 60% !important;
    color: $white;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 10px 25px;
  }
  ::-webkit-input-placeholder {
    color: $white;
  }
  ::-moz-placeholder {
    color: $white;
  }
  :-ms-input-placeholder {
    color: $white;
  }
  :-moz-placeholder {
    color: $white;
  }
}
.btn-email {
  border-radius: 5px;
  padding: 10px 35px;
  background-color: $white;
  color: $secondary_color;
  margin-left: 15px;
  margin-top: -5px;
  &:hover {
    color: $primary_color;
  }
}
/*=====================
    13.Contact section ends
==========================*/

/*=====================
    14.footer section start
==========================*/
.social-bg {
  background-color: $primary_color;
  padding: 15px 0;
}
.social-footer {
  margin-top: 30px;
  ul li {
    display: inline-flex;
    height: 35px;
    width: 35px;
    background: $primary_color;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transform: scale(1);
    transition: all 0.3s ease;
    a {
      color: $white !important;
    }
    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease;
    }
  }
}
.copy-right-bg {
  padding: 25px;
}
.copyright {
  margin: 20px 0 25px;
}
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}
@-webkit-keyframes ripple1 {
  0% {
    -ms-transform: scale(5.5); /* IE 9 */
    -webkit-transform: scale(5.5); /* Safari */
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    -ms-transform: scale(8.5); /* IE 9 */
    -webkit-transform: scale(8.5); /* Safari */
    transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
  100% {
    -ms-transform: scale(5.5); /* IE 9 */
    -webkit-transform: scale(5.5); /* Safari */
    transform: scale(5.5);
  }
}
@-webkit-keyframes ripple2 {
  0% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
  100% {
    -ms-transform: scale(5.5); /* IE 9 */
    -webkit-transform: scale(5.5); /* Safari */
    transform: scale(5.5);
  }
}
@keyframes ripple3 {
  0% {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari */
    transform: scale(1.5);
  }
  100% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
}
@-webkit-keyframes ripple3 {
  0% {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari */
    transform: scale(1.5);
  }
  100% {
    -ms-transform: scale(3.5); /* IE 9 */
    -webkit-transform: scale(3.5); /* Safari */
    transform: scale(3.5);
  }
}
/*=====================
    14.footer section ends
==========================*/

/*=====================
    15.Blog pages css start
==========================*/
.blog-list,
.blog-page {
  .news-slid {
    .blog-hover {
      .blog-details-list {
        li {
          border-color: $theme-gray;
          a {
            color: $theme-gray;
          }
        }
      }
      position: relative;
      transform: translateY(0px);
      padding: 0;
      h4 {
        color: $primary_color;
        margin-top: 5px;
        margin-bottom: 10px;
      }
      ul {
        margin-bottom: 10px;
      }
    }
  }
  .btn-theme {
    padding: 5px 14px;
    font-size: 13px;
  }
  margin-bottom: -40px;
  > div + div + div .blog-item {
    margin-top: 50px;
  }
}
.blog-page {
  margin-bottom: 0;
  .blog-details {
    &.news-slid {
      margin-bottom: 0;
    }
  }
  .animation-circle {
    i {
      background: #eee;
      z-index: -1;
    }
  }
  .news-slid {
    .news-box {
      &:before {
        display: none;
      }
    }
  }
}

.breadcrumb-bg {
  padding: 50px 0;
  margin: 0 auto;
  background-color: #f8f8fa;
  h2 {
    margin-bottom: 0;
    font-size: 28px;
    display: flex;
    align-items: center;
    height: 100%;
    text-transform: uppercase;
  }
}
.theme-breadcrumb {
  .breadcrumb-item {
    line-height: 1;
    & + .breadcrumb-item {
      &::before {
        color: $theme-gray;
      }
    }
  }
  float: right;
  padding: 15px 0;
  li {
    display: inline-block;
    color: $primary_color;
    text-decoration: none;
    &.active {
      a {
        color: $theme-gray;
      }
    }
    a {
      color: $primary_color;
      text-decoration: none;
    }
  }
}
.blog {
  width: 100vw;
  height: 100vh;
  margin-top: 0;
  align-items: center;
}
.inner-page {
  // padding-top: 110px;
  footer {
    background-color: #f8f8fa;
  }
  section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .navbar {
    background: $bg_gradiant;
  }
  .media h5 {
    color: $theme-gray;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: -5px !important;
  }
}
.blog-pagination {
  margin-top: 80px;
}
.blog-box {
  img {
    opacity: 1;
    transition: 0.3s ease-in-out;
    overflow: hidden;
  }
}
.blog-pagin {
  margin: 0;
  a.page-link {
    color: $primary_color;
    font-size: 16px;
    border: 1px solid $color_gallery_approx;
    transition: all 0.3s ease;
    border-radius: 5px;
    margin: 0 5px;
    padding: 0.5rem 0.9rem;
    &:hover {
      text-decoration: none;
      background-color: $bg_gradiant;
      border-color: $secondary_color;
      color: $white !important;
      transition: all 0.3s ease;
    }
  }
}
.sidebar-space {
  margin-bottom: 50px;
}
.blog-title {
  text-transform: uppercase;
  font-size: 18px;
  color: $theme-gray;
  letter-spacing: 1px;
  margin-top: 0;
}
.blog-divider {
  height: 1px;
  background-color: $color_gallery_approx;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}
.blog-description {
  margin: 20px auto;
}
.blog-cat-detail {
  ul {
    padding: 0;
    margin: 0;
    li {
      a {
        color: $theme-gray;
        text-transform: capitalize;
        font-size: 15px;
        text-decoration: none;
        transition: 0.3s ease;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover {
          color: $primary_color;
          transition: 0.3s ease;
        }
      }
      i {
        margin-right: 15px;
      }
    }
  }
  .marg-15 {
    margin: 15px auto;
  }
}
.reply-comment {
  margin: 30px auto;
  img {
    border-radius: 5px;
  }
  > div + div {
    margin-top: 20px;
  }
  p {
    margin-bottom: 0;
  }
}
.leave-coment {
  margin-top: 30px;
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: $primary_color;
  }
}
.theme-form {
  padding-left: 50px;
  padding-right: 50px;
  .form-group {
    margin-bottom: 20px;
  }
  .btn-custom {
    padding: 10px 40px;
  }
  .form-button {
    text-align: center;
  }
  .form-control {
    border-radius: 5px;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid $color_gallery_approx;
    font-size: 12px;
    text-align: center;
  }
  input::-webkit-input-placeholder {
    color: $color_silver_chalice_approx !important;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.1em;
  }
}
.recent-blog .media {
  margin-bottom: 15px;
  align-items: center;
  img {
    height: 60px;
    border-radius: 5px;
  }
  h5 {
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    color: $theme-gray;
    text-transform: capitalize;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50%;
  }
  p {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $theme-gray;
  }
}
.repay-coment > div + div {
  margin-top: 20px;
}
/*=====================
    15.Blog pages css ends
==========================*/

/*========================
  16. inner Page css start
==========================*/
.inner-page {
  .request-page {
    min-height: inherit;
    .main {
      padding: 0;
    }
    .auth-card {
      width: 400px;
    }
  }
}
.nav-item.dropdown {
  .dropdown-menu {
    min-width: 200px;
    background: $white;
    display: none;
    left: 15px;
    right: 0;
    margin: 0;
    border: 0;
    opacity: 0;
    padding: 20px;
    visibility: hidden;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 6px 12px $black_17;
    transition: all 0.6s ease-in;
  }
  &:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
li.nav-item.dropdown {
  &:last-child .dropdown-menu {
    right: 0;
    left: auto;
  }
  &:first-child .dropdown-menu {
    left: -105px;
  }
}
.dropdown-menu .nav-item .nav-link {
  padding: 5px;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: all 0.3s ease;
  text-transform: capitalize;
  &:hover {
    color: $primary_color !important;
    transition: all 0.3s ease;
  }
}
.about-chat .col-md-3:last-child .chat-box {
  border-right: none;
}
.swiper-slide img {
  width: 100%;
  margin-top: -25px;
  margin-bottom: -25px;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  position: relative;
  margin-top: 55px;
}
.animation-circle {
  &.absolute {
    i {
      left: -70px;
      top: 28%;
    }
  }
  &.gray {
    i {
      background: $bg_gradiant_gray;
    }
  }
}
#home-circles-hero {
  position: absolute;
  top: 50% !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  height: 100px;
  width: 100px;
  i {
    position: absolute;
    top: 50% !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    height: 100px;
    width: 100px;
  }
}
.main-subtitle {
  color: $primary_color !important;
}
.animation-circle i {
  position: absolute;
  height: 100px;
  width: 100px;
  background: $bg_gradiant;
  border-radius: 100%;
  opacity: 0.5;
  transform: scale(1.3);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;

  &:nth-child(2) {
    -webkit-animation: ripple2 3s linear infinite;
    animation: ripple2 3s linear infinite;
  }
  &:nth-child(3) {
    -webkit-animation: ripple3 3s linear infinite;
    animation: ripple3 3s linear infinite;
  }
  &:nth-child(4) {
    -webkit-animation: ripple4 3s linear infinite;
    animation: ripple4 3s linear infinite;
  }
}
.animation-circle-inverse i {
  z-index: -1;
  background: $color_gallery_approx;
  right: 0;
  box-shadow: 0 15px 30px 0 $black_11;
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  opacity: 0.3;
  transform: scale(1.3);
  -webkit-animation: ripple1 3s linear infinite;
  animation: ripple1 3s linear infinite;
  &:nth-child(2) {
    -webkit-animation: ripple2 3s linear infinite;
    animation: ripple2 3s linear infinite;
  }
  &:nth-child(3) {
    -webkit-animation: ripple3 3s linear infinite;
    animation: ripple3 3s linear infinite;
  }
}
.tags .badge-theme {
  padding: 5px 7px;
  font-size: 12px;
  background-color: $primary_color;
  margin-right: 2px;
  margin-bottom: 5px;
  color: $white;
  font-weight: 400;
  transition: 0.3s ease;
  &:hover {
    background-color: darken($secondary_color, 10%);
  }
}
.page-item.active .page-link {
  background-color: $primary_color;
  border-color: $primary_color;
}
.form-check-input.is-invalid ~ .form-check-label {
  color: $theme-gray;
  font-size: 15px;
}
// .margin-bottom > div + div + div + div .review-box {
//   margin-top: 30px;
// }
// .margin-bottom > div + div + div .review-box {
//   margin-top: 30px;
// }
.mb-50 {
  margin-bottom: 50px;
}
.request-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
/*========================
    16. inner Page css ends
==========================*/

/*========================
  17 Review page css start
==========================*/
.review-block {
  min-height: 80vh;
  padding: 115px 0 0 0;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  .animation-circle {
    i {
      background: #eee;
      z-index: -1;
    }
  }
}
.review-box {
  box-shadow: 0 11px 35px 2px $black_10;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  background: $white;
  height: 100%;
  transition: transform 0.75s;
  h5 {
    color: $primary_color;
    font-weight: 600;
    font-size: 18px;
  }
  p {
    margin-bottom: 0;
  }
  i {
    color: $color_saffron_approx;
  }
  img {
    height: 60px;
  }
  &:hover {
    transform: scale(1.025);
  }


}
/*========================
  17 Review page css Ends
==========================*/

/*========================
 18. Faq Page css start
==========================*/
.faq {
  .faq-row {
    align-items: center;
    justify-content: center;
    margin-bottom: -30px;
    margin-top: -17px;
  }
  .card {
    border-radius: 7px !important;
    border: 1px solid #eeeef7;
    .card-body {
      font-size: 16px;
      padding: 20px 30px;
      color: $theme-gray;
      border-top: 1px solid rgba(88, 96, 130, 0.2);
      letter-spacing: 0.05rem;
    }
    h5 {
      margin-top: 0;
    }
  }
  .accordion {
    .card {
      border-bottom: 1px solid #eeeef7 !important;
    }
    button {
      background-color: transparent;
      border: 0;
      width: 100%;
      text-align: left;
      color: $theme-gray;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.04rem;
      padding: 0;
      &[aria-expanded="true"] {
        color: $primary_color;
        i {
          &:before {
            content: "\f106";
          }
        }
      }
      i {
        float: right;
        transition: all 0.3s ease;
        padding-top: 5px;
      }
    }
  }
  .card-header {
    border: 0;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    padding: 15px 30px;
    &[aria-expanded="true"] {
      button {
        color: $primary_color;
      }
    }
    &.collapsed {
      i {
        transition: all 0.3s ease;
      }
    }
  }
}
.dropdown-btn {
  padding: 6px 8px 6px 40px;
  text-decoration: none;
  font-size: 16px;
  color: $white;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.title span {
  font-weight: 700;
}
/*========================
 18. Faq Page css ends
==========================*/

/*========================
 19.Download page css start
==========================*/
.download-nav {
  background-color: $primary_color;
}
.downoad-img ul {
  display: inline-block;
}
/*========================
  19.Download page css ends
==========================*/

/*========================
 20.coming soon pages css start
==========================*/
.cooming-soon {
  .home-right {
    .mobile-slid {
      position: relative;
    }
    .animation-circle-inverse {
      i {
        top: 30%;
        right: 40%;
        z-index: 0;
      }
    }
  }
}
#clockdiv {
  font-family: $font_1;
  color: $white;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  > div {
    padding: 10px;
    border-radius: 3px;
    border: 1px solid $primary_color;
    display: inline-block;
    margin: 0 5px;
  }
  div > span {
    padding: 15px;
    border-radius: 3px;
    background: $primary_color;
    display: inline-block;
  }
  .smalltext {
    color: $theme-gray;
  }
}
.smalltext {
  padding-top: 5px;
  font-size: 16px;
}
.clock-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.clock-bg {
  background-color: $primary_color;
  padding: 50px 0 35px;
  position: relative;
}
.social-bg-coming-soon {
  background-color: $primary_color;
  padding: 15px 0;
}
/*========================
 20.coming soon pages css ends
==========================*/

/*========================
 21.authentication pages css start
==========================*/
.auth-form {
  .social-btns {
    button {
      margin: 0 5px;
      border: none;
    }
    .fb,
    .ggl {
      &:hover {
        color: $white !important;
      }
    }
    .fb {
      background: #3b5998;
    }
    .ggl {
      background: #d85040;
    }
  }
  .or-saparator {
    position: relative;
    span {
      background-color: $white;
      padding: 0 10px;
      z-index: 2;
      position: relative;
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      margin-top: 1px;
      width: 100%;
      content: "";
      background: rgba(40, 56, 76, 0.1);
      z-index: 1;
    }
  }
  .forgot-pass {
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    color: $primary_color;
    position: absolute;
    border-left: 1px solid #d8d8d8;
    top: 6px;
    right: 5px;
  }
  .form-group {
    position: relative;
    margin-bottom: 20px;
    &:hover {
      label {
        opacity: 1;
        top: -9px;
      }
    }
    label {
      padding: 0 7px;
      background: $secondary_color;
      color: #fff;
      border-radius: 3px;
      opacity: 0;
      z-index: 1;
      position: absolute;
      top: 9px;
      left: 15px;
      transition: all 0.3s ease 0s;
    }
    .fa {
      line-height: 50px;
      color: $theme-gray;
      text-align: center;
      position: absolute;
      font-size: 16px;
      top: 15px;
      left: 20px;
      z-index: 1;
    }
  }
  .form-control {
    height: 45px;
    padding: 10px 52px;
    position: relative;
    transition: all 0.3s ease 0s;

    &:focus {
      border-color: $secondary_color;
      box-shadow: none;
    }
  }
  textarea {
    height: 100px !important;
  }
}
.auth-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  .animation-circle {
    i {
      top: 0;
      background: #eee;
    }
  }
  .animation-circle-inverse {
    i {
      bottom: 0;
    }
  }
  .profile-1 {
    bottom: 0;
  }
}
.auth-card {
  width: 90%;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  background-color: $white;
  box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.05);
  p {
    margin-bottom: 0;
  }
}
.form-control {
  &.is-valid {
    border-color: $color_alto_approx;
  }
  &.is-invalid {
    border-color: $color_alto_approx;
  }
}
.was-validated {
  .custom-select {
    &:valid {
      border-color: $color_alto_approx;
    }
    &:invalid {
      border-color: $color_alto_approx;
    }
  }
  .form-control {
    &:valid {
      border-color: $color_alto_approx;
    }
    &:invalid {
      border-color: $color_alto_approx;
    }
  }
}
.submit {
  cursor: pointer;
  border-radius: 5px;
  color: $white !important;
  background: $bg_gradiant;
  padding: 5px 20px;
  box-shadow: 0 0 20px 1px $black_4;
  transition: 0.5s all;
  &:hover {
    cursor: pointer;
    background-color: $primary_color;
  }
}
.thanks-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $color_zircon_approx;
  text-align: center;
}
.error-main {
  text-align: center;
  h4 {
    color: $theme-gray;
    font-weight: 400;
    margin: 20px 0;
  }
  .error-font {
    font-size: 300px;
    line-height: 1;
    font-weight: 900;
    color: $primary_color;
    letter-spacing: -25px;
    span {
      color: $theme-gray;
    }
  }
}
.main {
  background-color: $white;
  padding: 10px;
  text-align: center;
  padding-bottom: 0;
  .custom-select {
    &.is-valid {
      border-color: $color_alto_approx;
    }
    &.is-invalid {
      border-color: $color_alto_approx;
    }
  }
}
/*========================
 21.authentication pages css ends
==========================*/

/*========================
 22.landing page css
==========================*/
.btn-inverse {
  background: $white;
  color: $primary_color;
  border-radius: 5px;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.3s ease;
  &:hover {
    background: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
  }
  &.selected {
    background: $primary_color;
    color: $white;
  }
}
.landing-page {
  .theme-bg {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 65px;
    &.grab {
      min-height: auto;
    }

  }

  .btn-inverse {
    background: $white;
    color: $primary_color;
    border-radius: 5px;
    padding: 10px 30px;
    letter-spacing: 1px;
    font-weight: 600;
    transition: all 0.3s ease;
    &:hover {
      background: transparent;
      border: 1px solid white;
      color: white;
      cursor: pointer;
    }
  }
  .features-bg {
    background-color: #fbfbfb;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
  .back-img {
    p {
      font-size: 20px;
      line-height: 1.6;
      color: $white;
      font-weight: 400;
    }
  }
  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .landing-caption {
    font-size: 40px;
    line-height: 1.5;
    font-weight: bold;
    text-transform: capitalize;
  }
  .pt-70 {
    padding-top: 70px;
  }
  .border-shape {
    display: block;
    left: 0;
    margin: 15px auto;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    width: 80px;
    height: 4px;
  }
  .demo-description {
    line-height: 26px;
    text-align: center;
  }
  .mt-35 {
    margin-top: 35px;
  }
  .pc-bg {
    // background-image: $url_0;
    width: 100%;
    height: 500px;
    display: inline-block;
    position: relative;
    background-repeat: no-repeat;
    box-shadow: 0 0 10px 2px $color_alto_approx;
    background-color: $white;
    background-size: contain;
    max-width: 390px;
    &:hover .img-scroll {
      background-position: center 100% !important;
    }
  }
  .img-scroll {
    transition: all 9s ease-out 0s;
    background-position: center 0;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: calc(100% - 18px);
    top: 18px;
    left: 0;
    background-size: cover;
  }
  .demo-title {
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 0;
    color: $color_mine_shaft_approx;
    font-weight: 700;
  }
  .m-width {
    max-width: 1580px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .features-title {
    font-weight: 700;
    color: $theme-gray;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 25px;
  }
  .tapl-title {
    font-size: 36px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.3;
  }
  .tamp-d-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .rating-title {
    margin: 10px auto;
    letter-spacing: 1px;
    line-height: 1;
    font-size: 36px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #586082;
  }
  .rating-star {
    margin-top: 45px;
    animation: fadeIn 1.5s ease-in-out;
    img {
      margin-right: 25px;
    }
  }
  .btn-down {
    background-color: $primary_color;
    &:hover {
      background-color: transparent;
      color: $primary_color;
      border: 1px solid $primary_color;
    }
  }
  .navbar-brand {
    filter: brightness(0) invert(1);
  }
  .navbar {
    position: absolute;
    top: 0;
    z-index: 9;
    width: 100%;
  }
  .grab-img {
    height: 450px;
    overflow: hidden;
    float: none !important;
    width: auto !important;
  }
  .profile-2 {
    left: 90px;
    top: 0;
  }
  .emoji {
    right: 120px;
    bottom: -50px;
    left: unset;
    font-size: 25px;
  }
  .home-right {
    width: 400px;
    float: right;
    position: relative;
    .mobile-slid img {
      width: 355px;
      height: auto;
    }
  }
  .photo {
    position: absolute;
    top: 40px;
    right: -30px;
  }
  .gym {
    position: absolute;
    top: 140px;
    left: -150px;
    animation: movebounce 2.1s linear infinite;
  }
  .game {
    position: absolute;
    right: -110px;
    top: 270px;
    animation: moveleftbounce 2.1s linear infinite;
  }
  .msg {
    position: absolute;
    top: 427px;
    left: -150px;
    animation: moveleftbounce 2.1s linear infinite;
  }
  .broweser {
    position: absolute;
    bottom: 110px;
    right: -110px;
    animation: movebounce 2.1s linear infinite;
  }
  .love-emoji {
    position: absolute;
    bottom: 40px;
    left: 0;
  }
  .home-contain {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .animation-circle-inverse {
    opacity: 0.25;
    &.absolute i {
      bottom: 280px;
      left: 0;
    }
  }
  #mymenu {
    box-shadow: none;
  }

  .landing-circle1 img {
    position: absolute;
    top: 204px;
    left: 260px;
  }
  .landing-circle2 img {
    position: absolute;
    top: 235px;
    left: 125px;
  }
  .landing-circle3 img {
    position: absolute;
    top: 102px;
    left: 124px;
  }
  .container.m-width .pc-bg {
    height: 300px;
  }
  .pages-space > div + div + div + div {
    margin-top: 50px;
  }
  .color-variation span {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
    display: inline-block;
    border-radius: 5px;
    margin-top: 1rem;
  }
  .border-shape-two span {
    background-color: $color_alto_approx;
    height: 7px;
    width: 100%;
    display: block;
    left: 0;
    margin: 4px auto;
    text-align: center;
  }
  .features li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    text-align: center;
    border: 1px solid $color_wild_sand_approx;
    margin: 15px;
    border-radius: 5px;
    width: calc(25% - 30px);
    background-color: $white;
    &:nth-child(4) {
      border-right: none;
    }
    &:nth-child(8) {
      border-right: none;
    }
    &:nth-child(12) {
      border-right: none;
      border-bottom: none;
    }
    &:nth-child(16) {
      border-right: none;
    }
    &:nth-child(9) {
      border-bottom: none;
    }
    &:nth-child(10) {
      border-bottom: none;
    }
    &:nth-child(11) {
      border-bottom: none;
    }
  }
  .Copyright-section p {
    text-align: center;
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
  }
}
/*========================
 22.landing page css ends
==========================*/

.darkHeaderConstant {
  padding-top: 0;
  padding-bottom: 0;
  background: $bg_gradiant;
  box-shadow: 1px 1px 35px 0 $color_mine_shaft_40_approx;
  transition: all 0.3s ease;
  .nav-item {
    padding: 0px 5px;
    transition: all 0.3s ease;
  }
  .dropdown-menu .nav-item {
    padding: 0;
    .nav-link {
      &.active {
        color: #18e7d3;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.graph-box {
  box-shadow: 0 11px 35px 2px $black_10;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  background: $white;
  height: 100%;
  // transition: transform 0.75s;
  h5 {
    color: $primary_color;
    font-weight: 600;
    font-size: 18px;
  }
  p {
    margin-bottom: 0;
  }
  i {
    color: $color_saffron_approx;
  }
  img {
    height: 60px;
  }
  // &:hover {
  //     transform: scale(1.025);
  // }
}
.analytics-header {
  text-align: left;
  color: $primary_color;
  margin-left: 0;
  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 0 auto;
  }
}
.analytics-sub {
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.number-summary {
  text-align: left;
  color: $primary_color;
  padding: 10px;
  .number {
    font-size: 24px;
  }
}

.radio-filter {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  .option {

    height: 45px;
    width: 90px;
    padding: 10px;
    margin: 5px;
    background: $color_bon_jour_60_approx;
    transition: all 0.3s ease;
    color: $black;
    &:hover {
      background: $primary_color;
      color: $white;
      cursor: pointer;
    }
    &.selected {
      background: darken($primary_color, 0.2);
      color: $white;
    }
    &:first-of-type {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    .option {
      width: 100%;
    }
  }
}

#linkable-edit-content {
  flex: 1;
  height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.responsiveTable td.pivoted {
  padding-left: 0px !important;
  text-align: center !important;
}
    .shortHeight {
      height: 100% !important;
    }